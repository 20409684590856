<template>
  <div class="choose-worker app_content">
    <div class="warp_body">
      <div style="background: #FFFFFF">
        <van-cell-group inset>
          <!-- 输入任意文本 -->
          <van-field v-model="workType.name" readonly label="项目名称"/>

          <van-area-picker @areaSelect="areaConfirm"></van-area-picker>
          <van-field v-model="choosedTime" label="选择服务时间" label-width="105px" required type="textarea" rows="1" autosize
                     placeholder="点击选择服务时间"
                     readonly @click="chooseTime"/>

          <!--        <van-cell is-link @click="s"></van-cell>-->
          <van-popup
              v-model:show="showButton.showPopup"
              position="bottom"
          >
            <van-datetime-picker
                v-if="showButton.showStartTimePicker"
                v-model="queryCondition.startTime"
                type="datetime"
                title="选择开始时间"
                :min-date="startMinDate"
                :max-date="startMaxDate"
                @confirm="confirmStartTime"
                @cancel="cancleStartTime"
                :filter="filter"
            />
            <van-datetime-picker
                v-if="showButton.showEndTimePicker"
                v-model="queryCondition.endTime"
                type="datetime"
                title="选择结束时间"
                :min-date="endMinDate"
                :max-date="endMaxDate"
                @cancel="cancleEndTime"
                @confirm="confirmEndTime"
                :filter="filter"
            />
          </van-popup>

          <van-field
              v-model="selectCount"
              label-width="100px"
              label="选择服务人数"
          >
            <template #input>
              <van-stepper v-model="selectCount" integer="true"/>
            </template>
          </van-field>

          <!--          <van-cell center title="选择工人数量">-->
          <!--            <van-stepper v-model="value" />-->
          <!--          </van-cell>-->


        </van-cell-group>
        <div style="text-align: center;padding:20px 100px ">
          <van-button round plain block type="primary" @click="queryWorkers" color="#4DBAFD">
            确认选择
          </van-button>

        </div>
      </div>
      <div class="queryArea" v-if="showResult">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <van-checkbox-group v-model="checked">
              <div v-for="item in workerList" style="padding: 2px" v-bind:key="item">

                <div
                    style="display: flex;font-size: 12px;
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    height: 50px;padding: 10px;
                    margin:0 10px;align-items:center;line-height:28px;position: relative">

                  <div style="height: 100%;width: 15%;">
                    <van-image
                        width="100%"
                        height="100%"
                        :src="item.img"
                    />
                  </div>

                  <div style="margin-left: 20px">
                    <div>
                      <div>工人编号:
                        <span> {{ item.workerNo }} </span>
                        &nbsp;&nbsp;
                        <span>{{ item.level }}</span>
                      </div>

                    </div>
                    <div style="display: flex;justify-content: space-around">
                      <div>区域： <span>{{ item.areaName }}</span>&nbsp;&nbsp;</div>
                      <div>星评：  <span>{{ item.score ? item.score : '暂无' }}</span>&nbsp;&nbsp;</div>
                      <div>服务价格: <span style="color: #4DBAFD;font-weight: 600;font-size: 14px">{{ item.price }}</span>&nbsp;&nbsp;</div>
                    </div>
                  </div>
                  <div style="position: absolute;right: 10px;top: 30%;">
                    <van-checkbox
                        :name="item"
                        :ref="el => checkboxRefs[item.uid] = el"
                        icon-size="14px"
                        @click="changeSelect"
                        @click.stop
                    />
                  </div>
                </div>
              </div>
            </van-checkbox-group>
          </van-list>
        </van-pull-refresh>
      </div>
      <van-action-bar>
        <div style="padding: 20px">已选工人：{{ checked.length }}人,合计:<span style="color: #FA421C">{{ priceTotal }}</span> 元
        </div>
        <van-action-bar-button color="rgb(77, 186, 253)" type="danger" :disabled=!canSubmit text="提交订单"
                               @click="previewOrder"/>
      </van-action-bar>
    </div>

  </div>
</template>

<script>
import {onBeforeUpdate, onMounted, reactive, toRefs,} from 'vue'
import vanAreaPicker from "../components/common/vanAreaPicker";
import req from "../request/request";
import API_ORDER from "../api/Order";
import router from "../router";
import {Dialog, Toast} from "vant";

export default {
  name: 'Register',
  components: {vanAreaPicker},

  setup() {

    onMounted(() => {
      data.startMinDate = methodsMap.floorDate(new Date())
      data.startMaxDate = methodsMap.addDate(new Date(), 'day', 7)
      data.workType = JSON.parse(sessionStorage.getItem("wokerType"))
      data.query.workTypeId = data.workType.id
      methodsMap.getSToken()
    });
    onBeforeUpdate(() => {
      data.checkboxRefs.value = [];
    });

    const data = reactive({
          sToken: '',
          showResult: false,
          checkboxRefs: [],
          checked: [],
          showAreaPicker: false,
          refreshing: false,
          query: {
            areaId: '',
            workTypeId: ''
          },
          workerList: [],
          canSubmit: false,
          priceTotal: 0,
          queryResult: {
            total: 0,
            pageSize: 0,
            pages: 1,
            records: [],
            current: 1
          },

          loading: false,
          finished: false,
          workType: {
            name: '',
            id: ''
          },
          showButton: {showStartTimePicker: false, showPopup: false, showEndTimePicker: false},

          choosedTime: '',
          startMinDate: new Date(),
          startMaxDate: new Date(),
          endMinDate: {},
          endMaxDate: {},
          queryCondition: {startTime: '', endTime: ''},
          selectCount: 1,
        },
    )
    const methodsMap = {
      getSToken: () => {
        req.request(API_ORDER.sToken, null, {method: 'POST'}).then((res) => {
          console.log(res.headers)
          data.sToken = res.headers[`s-token`]
        })
      },
      changeSelect: () => {
        console.log(data.checked)
        data.priceTotal = 0
        data.checked.forEach((s) => {
          data.priceTotal += s.price
        })
        if (data.checked.length != 0) {
          data.canSubmit = true
        } else {
          data.canSubmit = false
        }
      },
      addDate: (date, type, numbers) => {
        if (type == 'day') {
          // let time = new Date(date.getFullYear(), date.getMonth(), date.getDay() + numbers)
          // return new Date(time.setHours(22))
          return new Date(date.setDate(date.getDate() + numbers))
        } else if (type == 'hour') {
          return new Date(date.setHours(date.getHours() + numbers))
        } else if (type == 'minute') {
          return new Date(date.setMinutes(date.getMinutes() + numbers))
        }
      },

      floorDate: (date) => {

        let m = date.getMinutes();
        let h = date.getHours();
        if (m >= 30) {
          m = 0
          h += 1
        }
        date.setHours(h)
        date.setMinutes(m)
        return new Date(date)

      },
      toggle: (id) => {
        data.checkboxRefs[id].toggle();
      },
      formatDate: (date, fmt) => {
        var o = {
          "M+": date.getMonth() + 1, //月份
          "d+": date.getDate(), //日
          "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
          "H+": date.getHours(), //小时
          "m+": date.getMinutes(), //分
          "s+": date.getSeconds(), //秒
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度
          "S": date.getMilliseconds() //毫秒
        };
        var week = {
          "0": "/u65e5",
          "1": "/u4e00",
          "2": "/u4e8c",
          "3": "/u4e09",
          "4": "/u56db",
          "5": "/u4e94",
          "6": "/u516d"
        };
        if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        if (/(E+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" : "/u5468") : "") + week[date.getDay() + ""]);
        }
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
          }
        }
        return fmt;
      },
      areaConfirm: (val) => {
        data.query.areaId = val
      },
      onLoad: () => {
        data.showResult = true
        if (data.refreshing) {
          data.refreshing = false;
        }
        console.log('workerList', data.workerList)
        console.log('workerList json', JSON.stringify(data.workerList))
        data.loading = false;
        if (data.workerList.length >= data.queryResult.total) {
          data.finished = true;
        } else {
          methodsMap.apiQueryWorkers(data.query)
        }
      },
      onRefresh: () => {
        // 清空列表数据
        // data.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        data.loading = true;
        data.query.page++
        data.query.pageSize = 15

        methodsMap.onLoad();
      },
      filter: (type, options) => {
        if (type === 'minute') {
          return options.filter((option) => Number(option) % 30 === 0);
        }
        if (type === 'hour') {
          return options.filter((option) => option >= 7 && option <= 22);
        }
        return options;
      },
      queryWorkers: () => {

        if (!data.query.areaId) {
          Toast("请选择地域！")
          return
        }
        if (!data.choosedTime) {
          Toast("请选择服务时间！")
          return;
        }

        data.showResult = true
        data.query.page = 1
        data.query.pageSize = 15
        data.query.startTime = methodsMap.formatDate(data.queryCondition.startTime, 'yyyy/MM/dd HH:mm')
        data.query.endTime = methodsMap.formatDate(data.queryCondition.endTime, 'yyyy/MM/dd HH:mm')
        console.log(data.query)
        data.workerList = []
        methodsMap.apiQueryWorkers(data.query)
      },
      apiQueryWorkers: (params) => {
        req.request(API_ORDER.queryUsers, params, {method: 'GET'})
            .then((res) => {
              data.queryResult = res.data.data
              data.workerList = [...data.workerList, ...res.data.data.records]
              console.log('req', data.workerList)
            })
      },
      chooseTime: () => {
        data.showButton = {showStartTimePicker: true, showPopup: true, showEndTimePicker: false}

      },
      confirmStartTime: () => {
        data.showButton = {showStartTimePicker: false, showPopup: true, showEndTimePicker: true}
        data.endMinDate = methodsMap.addDate(data.startMinDate, 'minute', 1)
        data.endMaxDate = methodsMap.addDate(new Date(), 'day', 7)
      },
      cancleStartTime: () => {
        data.showButton = {showStartTimePicker: false, showPopup: false, showEndTimePicker: false}
      },
      confirmEndTime: () => {
        data.showButton = {showStartTimePicker: false, showPopup: false, showEndTimePicker: false}
        data.choosedTime = methodsMap.formatDate(data.queryCondition.startTime, 'yyyy-MM-dd HH:mm') + "至" +
            methodsMap.formatDate(data.queryCondition.endTime, 'yyyy/MM/dd HH:mm')
      },
      cancleEndTime: () => {
        data.showButton = {showStartTimePicker: true, showPopup: true, showEndTimePicker: false}

      },
      previewOrder: () => {
        let list = []
        data.checked.forEach((w) => {
          list.push(w.uid)
        })
        if (list.length != data.selectCount) {
          Toast("请确认所选服务人数和和勾选服务工人是否一致！")
          return
        }
        let reqJson = {
          startTime: data.queryCondition.startTime,
          endTime: data.queryCondition.endTime,
          uidList: list,
          workTypeId: data.workType.id,
        }
        console.log("req", reqJson)
        console.log("data.sToken", data.sToken)
        Dialog.confirm({
          title: '订单',
          message: '是否提交订单？',
        }).then(() => {
          req.request(API_ORDER.submitOrder, reqJson, {method: 'POST'}, {message: '提交订单中...'}, {
            's-token': data.sToken
          })
              .then((res) => {
                if (res.data.code == 200) {
                  sessionStorage.removeItem("workType")
                  router.replace("Result")
                }
              })
        }).catch(() => {
          // on cancel
        });


      }

    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  },


}
</script>

<style lang="less">
.app_content {
  .warp_body {
    .van-stepper {
      --van-stepper-button-icon-color: rgb(77, 186, 253);
    }
  }

  .queryArea {

    height: 500px;
    overflow: scroll;


    .list {
      display: flex;
      justify-content: space-between;


      .van-cell {
        font-size: 10px;
      }
    }

  }
}
</style>