
<template>
  <van-field
      v-model="areaText"
      required
      is-link
      readonly
      name="picker"
      label="单位地域"
      placeholder="点击选择区域"
      @click="getAreaList"
      :rules="[{ validator: validatorAreaMessage }]"
  />
  <van-popup v-model:show="showAreaPicker" position="bottom">
    <van-picker
        :columns="areaList"
        @confirm="onConfirmArea"
        @cancel="showAreaPicker = false"
    />
  </van-popup>
</template>

<script>
import {reactive, toRefs} from 'vue'
import req from "../../request/request";
import API from "../../api/User";

export default {
  name: 'vanAreaPicker',
  components: {},
  setup(props, context) {
    let data = reactive({
      showAreaPicker: false,
      areaText: '',
      area: '',
      verificationCode: '',
      areaList: [],
    });

    const methodMap = {

      onConfirmArea: (item) => {
        data.showAreaPicker = false
        data.areaText = item.text
        data.area = item.value
        context.emit('areaSelect', item.id)
      },

      validatorAreaMessage: (val) => {
        if (!val) {
          return '请选择所在区域'
        }
      },
      getAreaList: () => {
        data.showAreaPicker = true
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
              data.areaList = res.data.data
            })
      },
    }

    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less" scoped>
.vanButton {

  .vanButtonFix {
    position: fixed;
    height: 30px;
    bottom: 20px;
    width: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

</style>